.MuiContainer-root.main_cnt{
  height:80vh;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.MuiPaper-root.cntnr{
  height: 100%;
  border-radius: 0px;
}
/* .switch-wrapper {
  position: relative;
} */

/* .switch-wrapper {
  position: absolute;
} */